export default {
   namespaced: true,
   state: {
      textSmall: false,
      darkMode: false
   },
   mutations: {
      setTextSmall(state) {
         state.textSmall = !state.textSmall;
      },
      setDarkMode(state) {
         state.darkMode = !state.darkMode;
      },
   },
   // actions: {
   //    setTextSmall({ commit }) {
   //       commit('setTextSmall')
   //    },
   //    setDarkMode({ commit }) {
   //       commit('setDarkMode')
   //    }
   // }
   // getters: {
   //    getDarkMode: state => {
   //       return state.darkMode;
   //    },
   // },
};