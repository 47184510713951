import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
   // {
   //   path: '/',
   //   name: 'home',
   //   component: HomeView
   // },
   // {
   //   path: '/about',
   //   name: 'about',
   //   // route level code-splitting
   //   // this generates a separate chunk (about.[hash].js) for this route
   //   // which is lazy-loaded when the route is visited.
   //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
   // }
   {
      path: '',
      component: () => import('../layouts/DeafultLayout'),
      // meta: { menu: 'inicio' },
      children: [
         {
            path: '/',
            name: 'HomeView',
            component: () => import('../views/HomeView'),
            meta: {
               breadcrumb: {
                  title: '<i class="nav-icon fas fa-home"></i> Início',
                  item: [
                     {
                        text: 'Início'
                     },
                  ]
               }
            }
         },
         {
            path: '/about',
            name: 'AboutView',
            component: () => import('../views/AboutView'),
            meta: {
               breadcrumb: {
                  title: 'Sobre',
                  item: [
                     {
                        text: 'Sobre'
                     },
                  ]
               }
            }
         },
         {
            path: '/cadastro/cliente',
            name: 'ClientesView',
            component: () => import('../views/Cadastro/ClientesView'),
            meta: {
               breadcrumb: {
                  title: '<i class="nav-icon fas fa-users"></i> Clientes',
                  item: [
                     {
                        text: 'Cadastro'
                     },
                     {
                        text: 'Clientes'
                     },
                  ]
               }
            }
         },
      ]
   },
]

const router = new VueRouter({
   mode: 'history',
   base: process.env.BASE_URL,
   routes,
   linkActiveClass: "active", // classe para links ativos
   linkExactActiveClass: "active", // classe para links ativos com correspondência exata
})

export default router
